import { Helmet } from 'react-helmet';
import React, { useState, useEffect } from 'react';
// import { useParams } from 'react-router-dom';
import HeaderTab from '../comp/HeaderTab'; 
import FooterTab from '../comp/FooterTab';
import BreadcrumThankyou from '../comp/BreadcrumThankyou';



function DonateNow() {

    const [donationData, setDonationData] = useState(null); 
    const [donationNaira1, setNaira1] = useState(null); 
    const [donationNaira2, setNaira2] = useState(null); 
    const [donationusd1, setusd1] = useState(null); 
    const [donationusd2, setusd2] = useState(null); 
  //  const { id } = useParams();

const urlParams = new URLSearchParams(window.location.search);
const id = urlParams.get('id'); // id will be "123"

    console.log(id) ;
  
    useEffect(() => {
        const fetchData = async () => {
          try {
            const response = await fetch(`https://secure.tynachyomaa.com/api/thankyou?id=${id}`); // Replace with your API endpoint
            const data = await response.json();
            setDonationData(data);
            setNaira1(data.naira1);
            setNaira2(data.naira2);
            setusd1(data.usd1);
            setusd2(data.usd2);             
          } catch (error) {
            console.error('Error fetching donation data:', error);
          } 
        };
    
        fetchData();
      }, [id]);
      
    
      // ... rest of your thank you page component, using donationData
      if (!donationData) {
        return <div>Loading...</div>;
      }

  return (
    <div>
        <Helmet>
        <title> Donate | Support Our Cause: Nurturing Hope </title>
        <meta name="description" content="Learn how your donations help provide essential care, education, and support to orphans, youth, and widows. Make a difference today." />
        <meta name="keywords" content="donate, TynaChyomaa, charitable foundation, support, orphans, youth empowerment, vocational education, widows " />
      </Helmet>
        <HeaderTab /> 
        <BreadcrumThankyou />

        {/* <!--Error Page Start--> */}
        <section className="error-page">
            <div className="error-page-shape" style={{backgroundImage: 'url(assets/images/shapes/error-page-shape.png)' }}>
            </div>
            <div className="container">
                <div className="row">
                    <div className="col-xl-12">
                        <div className="error-page__inner22">
                        <div className="col-xl-8 mx-auto">
                          
<p className="donation-details__summary-text-1">  Dear {donationData.name}, </p><br />

<p className="donation-details__summary-text-2">
We sincerely appreciate your interest in supporting TynaChyomaa Charitable Foundation. Your desire to make a difference in the lives of others is commendable.   </p>  

 <p className="donation-details__summary-text-2"> 
 We have received your donation form and are grateful for your consideration. To complete your donation, kindly transfer the desired amount of ( {donationData.amount} ) to the following accounts:
    </p><br />
 
    
 <p className="donation-details__summary-text-2"> </p>

{donationusd1.status === '100' ?  (
    <div> 
 {donationusd1.title}<br /> 
<table>
    <tbody>
      <tr><td>Bank Name:</td> <td>{donationusd1.bankName}</td></tr>
    <tr><td>Account Name:</td> <td>{donationusd1.accountName}</td></tr>
    <tr><td>Account Number:</td> <td>{donationusd1.accountNo}</td></tr>
    <tr><td>SWIFT Code:</td> <td>{donationusd1.SWIFTCode}</td></tr>  
    </tbody>
    
</table>
<br /> 
    </div>
) : (
      <div></div>
)
}

 


{donationusd2.status === '100' ?  (
    <div>
     {donationusd2.title}<br /> 
    <table>
    <tbody>
        <tr><td>Bank Name:</td> <td>{donationusd2.bankName}</td></tr>
        <tr><td>Account Name:</td> <td>{donationusd2.accountName}</td></tr>
        <tr><td>Account Number:</td> <td>{donationusd2.accountNo}</td></tr>
        <tr><td>SWIFT Code:</td> <td>{donationusd2.SWIFTCode}</td></tr> 
        </tbody>
    </table>
    <br /> 
    </div>
) : (
      <div></div>
)
}



{donationNaira1.status === '100' ?  (
    <div>
  {donationNaira1.title}<br /> 
<table>   
    <tbody>
     <tr><td>Bank Name:</td> <td>{donationNaira1.bankName}</td></tr>
    <tr><td>Account Name:</td> <td>{donationNaira1.accountName}</td></tr>
    <tr><td>Account Number:</td> <td>{donationNaira1.accountNo}</td></tr>
    <tr><td>SWIFT Code:</td> <td>{donationNaira1.SWIFTCode}</td></tr> 
</tbody>
     
</table>
<br />  
</div>
) : (
      <div></div>
)
}

{donationNaira2.status === '100' ?  (
    <div> 
 {donationNaira2.title}<br /> 
<table> 
<tbody> 
    <tr><td>Bank Name:</td> <td>{donationNaira2.bankName}</td></tr>
    <tr><td>Account Name:</td> <td>{donationNaira2.accountName}</td></tr>
    <tr><td>Account Number:</td> <td>{donationNaira2.accountNo}</td></tr>
    <tr><td>SWIFT Code:</td> <td>{donationNaira2.SWIFTCode}</td></tr>
    </tbody>
</table>
<br />
    </div>
) : (
      <div></div>
)
}

<p className="donation-details__summary-text-2"> A dedicated member of our team will be in touch with you shortly to acknowledge your donation and provide any necessary information.
    </p> 
    
    
 <p className="donation-details__summary-text-2"> Thank you once again for your generosity and support.
    </p>
    

 <p className="donation-details__summary-text-2">
 Sincerely,<br /> 
TynaChyomaa Charitable Foundation Team.<br />
Plot 299 Uratta East layout, MCC Road, <br />
Owerri North LGA, Imo state - Nigeria<br />
Email: info@TynaChyomaa.com<br />
Tel: +2348033419924
 
    </p>  

 
                            <a href="/" className="thm-btn error-page__btn">back to home</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        {/* <!--Error Page End--> */}





        <FooterTab />
    </div>
  );
}
export default DonateNow;
