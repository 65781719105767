import React from 'react';
// import { Link } from 'react-router-dom';s

function DonationTab() {
  return (
    <div>
    {/*Causes One Start*/}
    <section className="causes-one">
            <div className="container">
                <div className="section-title text-center">
                    <span className="section-title__tagline">Help & donate  now</span>
                    <h2 className="section-title__title">
                    Let's Build a Better Future Together. Partner with us to create lasting change. 
                    </h2>
                </div>
                <div className="row">
                    {/*Causes One Single Start*/}
                    <div className="col-xl-4 col-lg-4 wow fadeInUp" data-wow-delay="100ms">
                        <div className="causes-one__single">
                            <div className="causes-one__img">
                                <img src="assets/images/resources/causes-1-1.jpg" alt="" />
                                <div className="causes-one__cat">
                                    <p>Education Support</p>
                                </div>
                            </div>

                            <div className="causes-one__content">
                                <h3 className="causes-one__title"><a href="donation-details.html">
                                    Invest in the Future Through Education</a>
                                </h3>
                                <p className="causes-one__text">
                                Your support helps provide quality education to children in need. Together, we can build a brighter future.
                                </p>
                                {/* <div className="causes-one__progress">
                                    <div className="causes-one__progress-shape" style={{backgroundImage: 'url(assets/images/shapes/causes-one-progress-shape-1.png)' }}>
                                    </div>
                                    <div className="bar">
                                        <div className="bar-inner count-bar" data-percent="36%">
                                            <div className="count-text">36%</div>
                                        </div>
                                    </div>
                                    <div className="causes-one__goals">
                                        <p><span>$25,270</span> Raised</p>
                                        <p><span>$30,000</span> Goal</p>
                                    </div>
                                </div> */}
                            </div>
                        </div>
                    </div>
                    {/*Causes One Single End*/}
                    {/*Causes One Single Start*/}
                    <div className="col-xl-4 col-lg-4 wow fadeInUp" data-wow-delay="200ms">
                        <div className="causes-one__single">
                            <div className="causes-one__img">
                                <img src="assets/images/resources/causes-1-2.jpg" alt="" />
                                <div className="causes-one__cat">
                                    <p>Medical Support</p>
                                </div>
                            </div>
                             


                            <div className="causes-one__content">
                                <h3 className="causes-one__title"><a href="donation-details.html">
                                    Provide Healing and Hope</a>
                                </h3>
                                <p className="causes-one__text">Help us deliver essential medical care and support to those in need. Your compassion can save lives.</p>
                                {/* <div className="causes-one__progress">
                                    <div className="causes-one__progress-shape" style={{backgroundImage: 'url(assets/images/shapes/causes-one-progress-shape-1.png)' }}>
                                    </div>
                                    <div className="bar">
                                        <div className="bar-inner count-bar" data-percent="36%">
                                            <div className="count-text">36%</div>
                                        </div>
                                    </div>
                                    <div className="causes-one__goals">
                                        <p><span>$25,270</span> Raised</p>
                                        <p><span>$30,000</span> Goal</p>
                                    </div>
                                </div> */}
                            </div>
                        </div>
                    </div>
                    {/*Causes One Single End*/}
                    {/*Causes One Single Start*/}
                    <div className="col-xl-4 col-lg-4 wow fadeInUp" data-wow-delay="300ms">
                        <div className="causes-one__single">
                            <div className="causes-one__img">
                                <img src="assets/images/resources/causes-1-3.jpg" alt="" />
                                <div className="causes-one__cat">
                                    <p>Food Support</p>
                                </div>
                            </div> 
                            <div className="causes-one__content">
                                <h3 className="causes-one__title"><a href="donation-details.html">
                                Nourish Lives, Build Communities</a>
                                </h3>
                                <p className="causes-one__text">
                                Fight hunger and malnutrition by supporting our feeding programs. Your generosity makes a difference. </p>
                                {/* <div className="causes-one__progress">
                                    <div className="causes-one__progress-shape" style={{backgroundImage: 'url(assets/images/shapes/causes-one-progress-shape-1.png)' }}>
                                    </div>
                                    <div className="bar">
                                        <div className="bar-inner count-bar" data-percent="36%">
                                            <div className="count-text">36%</div>
                                        </div>
                                    </div>
                                    <div className="causes-one__goals">
                                        <p><span>$25,270</span> Raised</p>
                                        <p><span>$30,000</span> Goal</p>
                                    </div>
                                </div> */}
                            </div>
                        </div>
                    </div>
                    {/*Causes One Single End*/}
                </div>
            </div>
        </section>
        {/*Causes One End*/}

        </div>
  );
}
export default DonationTab;
