import React from 'react';
// import { Link } from 'react-router-dom';

function ContactDetails() {
  return (
    <div>
 {/* <!--Contact One Start--> 
 
 Plot 299 Uratta East layout, MCC Road, Owerri North LGA, imo state .

5C Michael Ogun street, GRA. Ikeja - Lagos State.

13 Chestnut Avenue eskerhills portlaoise, County Laois, Republic of Ireland.

info@TynaChyomaa.com

tinachiomaa@gmail.com

+2348033419924

+353870906862
*/}
        <section className="contact-one">
            <div className="container">
                <div className="contact-one__inne">
                    <ul className="list-unstyled contact-one__list">
                        <li className="contact-one__single">
                            <div className="contact-one__icon">
                                <span className="icon-phone-call"></span>
                            </div>
                            <div className="contact-one__content">
                                <p className="contact-one__sub-title">Helpline</p>
                                <h3 className="contact-one__number"><a href="tel:+2348033419924">+234 803 341 9924</a></h3>
                                <h3 className="contact-one__number"><a href="tel:+353870906862"> +35 387 090 6862</a></h3>
                            </div>
                        </li>
                        <li className="contact-one__single">
                            <div className="contact-one__icon">
                                <span className="icon-message"></span>
                            </div>
                            <div className="contact-one__content">
                                <p className="contact-one__sub-title">Send email</p>
                                <h3 className="contact-one__number"><a href="mailto:info@TynaChyomaa.com">info@TynaChyomaa.com</a></h3>
                                <h3 className="contact-one__number"><a href="mailto:tinachiomaa@gmail.com"> tinachiomaa@gmail.com </a></h3>
                            </div>
                        </li>
                        <li className="contact-one__single">
                            <div className="contact-one__icon">
                                <span className="icon-location"></span>
                            </div>
                            <div className="contact-one__content">
                                <p className="contact-one__sub-title"> Plot 299 Uratta East layout, MCC Road, </p>
                                <h3 className="contact-one__number"> Owerri North LGA, Imo State </h3>

                                
                                <p className="contact-one__sub-title"> 5C Michael Ogun street, GRA.</p>
                                <h3 className="contact-one__number">Ikeja - Lagos State.</h3>

                                
                                <p className="contact-one__sub-title"> 13 Chestnut Avenue eskerhills portlaoise,  </p>
                                <h3 className="contact-one__number"> County Laois, Republic of Ireland.</h3>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </section>
        {/* <!--Contact One End--> */}

        </div>
  );
}
export default ContactDetails;
