import React from 'react';
import { Link } from 'react-router-dom';

 



function HeaderTab() {
  return (
    <div>
    <div className="custom-cursor__cursor"></div>
    <div className="custom-cursor__cursor-two"></div>
 


    <div className="page-wrapper">
        <header className="main-header">
            <nav className="main-menu">
                <div className="main-menu__wrapper">
                    <div className="main-menu__wrapper-inner">
                        <div className="main-menu__left">
                            <div className="main-menu__logo">
                                <Link to="about">
                                    <img src="assets/images/resources/logo-1.png" alt="" />
                                    </Link>
                            </div>
                            <div className="main-menu__shape-1 float-bob-x " >
                                <img src="assets/images/shapes/main-menu-shape-1.png" alt="" />
                            </div>
                        </div>

                        <div className="main-menu__right " style={{ float: 'right' }} >
                            <div className="main-menu__right-top">
                                <div className="main-menu__right-top-right">
                                    {/* <div className="main-menu__volunteers">
                                        <div className="main-menu__volunteers-icon">
                                            <img src="assets/images/icon/main-menu-heart-icon.png" alt="" />
                                        </div>
                                       <div className="main-menu__volunteers-text-box">
                                       <p className="main-menu__volunteers-text">
                                            {/*    
                                         <a href="become-volunteer.html">Become a  <span>volunteers</span></a>*
                                         </p> 
                                        </div>
                                    </div> */}
                                </div>
                                <div className="main-menu__right-top-right" style={{ float: 'right' }}>
                                    <div className="main-menu__right-top-address">
                                        <ul className="list-unstyled main-menu__right-top-address-list">
                                            <li>
                                                <div className="icon">
                                                    <span className="icon-phone-call"></span>
                                                </div>
                                                <div className="content">
                                                    <p>Helpline</p>
                                                    <h5><a href="tel:980009630"> +234 803 341 9924 </a></h5>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="icon">
                                                    <span className="icon-message"></span>
                                                </div>
                                                <div className="content">
                                                    <p>Send email</p>
                                                    <h5><a href="mailto:info@tynachyomaa">info@tynachyomaa.com</a>
                                                    </h5>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="icon">
                                                    <span className="icon-location"></span>
                                                </div>
                                                <div className="content">
                                                    <p>HQ - Owerri</p>
                                                    <h5>Imo State, Nigeria</h5>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="main-menu__right-top-social">
                                        <a href="https://facebook.com/"><i className="fab fa-twitter"></i></a>
                                        <a href="https://facebook.com/"><i className="fab fa-facebook"></i></a>
                                        <a href="https://facebook.com/"><i className="fab fa-pinterest-p"></i></a>
                                        <a href="https://facebook.com/"><i className="fab fa-instagram"></i></a>
                                    </div>
                                </div>
                            </div>
                            <div className="main-menu__right-bottom">
                                <div className="main-menu__main-menu-box">
                                    <a href="/" className="mobile-nav__toggler"><i className="fa fa-bars"></i></a>
   
                                    
                                    <nav>
                                    <ul className="main-menu__list">
                                         
                                    <li><a href="/">Home </a></li>
                                    <li><a href="about">About Us</a></li>
                                    {/* <li><a href="programs">Programs</a></li> */}
                                    <li><a href="BecomeVolunteer">Volunteer</a></li>
                                    <li><a href="events">Events</a></li>
                                    <li><a href="gallery">Gallery</a></li> 
                                    <li>  <a href="contact">Contact</a> </li>
                                    </ul>
                                    </nav>
     

                                </div>
                                <div className="main-menu__main-menu-content-box">
                                    <div className="main-menu__search-cat-btn-box">
                        <div className="main-menu__search-box">
                            <a href="/" className="main-menu__search search-toggler icon-magnifying-glass"> </a>
                        </div>
                        <div className="main-menu__cat-box">
                            <a href="/" className="main-menu__cart icon-shopping-cart"> </a>
                        </div>
                                        <div className="main-menu__btn-box">
                                            <a href="donations" className="main-menu__btn"> <span className="fa fa-heart"></span> Donate
                                                now</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </nav>
        </header>
        </div>
        </div>
  );
}
export default HeaderTab;
