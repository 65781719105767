import React, { useState }  from 'react';
// import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';

function DonationForm() {
    
const navigate = useNavigate();
function displaySuccessDialog(title,message) { 
    Swal.fire({
        title: `${title}`,
        text: `${message}`,
        icon: 'success',
        confirmButtonText: 'OK'
    });
    }
 
  const [amount, setInputAmount] = useState('');
  const [firstName, setInputFirstName] = useState(''); 
  const [lastName, setInputLastName] = useState(''); 
  const [email, setInputEmail] = useState(''); 
  const [phone, setInputPhone] = useState(''); 
  const [address, setInputAddress] = useState('');  
  const [country, setInputCountry] = useState(''); 
  const [comment, setInputComment] = useState(''); 
 

const handleSubmit = async (event) => {
    event.preventDefault();
    const formData = {
        amount,
        firstName,
        lastName,
        email,
        phone,
        address, 
        country, 
        comment,    
  
      };
    // console.log(formData)
    try {
      const response = await fetch('https://secure.tynachyomaa.com/api/donation', 
         {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });
        
      const data = await response.json(); 
    //   console.log(response) 
      console.log(data) 
      if (data.success)   
        {
        displaySuccessDialog(data.message, 'Your donation application has been successfully received.'); 
        navigate('/ThankYou?id='+data.did); 
      } else {
        console.error('Error submitting form'); 
      }
    } catch (error) {
      console.log('Error:', error); 
    }
  };




  return (
  
 <div className="col-xl-8 col-lg-7">
                        <div className="donate-now__left">
                            
                            <div className="donate-now__personal-info-box">
                                <h3 className="donate-now__title"> Enter your donation </h3>
                                <form className="donate-now__personal-info-form"     onSubmit={handleSubmit}>
                                    <div className="row">
                                        <div className="col-xl-6">
                                            <div className="donate-now__personal-info-input">
                                        <input 
                                        type="text" 
                                        placeholder="Donation Amount" 
                                        name="amount"  
                                        value={amount} 
                                        onChange={(e) => setInputAmount(e.target.value)} 
                                         />
                                            </div>
                                        </div>
                                            <div className="col-xl-6">
                                            <div className="donate-now__personal-info-input">
                                                <input 
                                                type="text" 
                                                placeholder="Country" 
                                                name="country"  
                                                value={country} 
                                                onChange={(e) => setInputCountry(e.target.value)} 
                                                />
                                            </div>
                                        </div>
                                        <div className="col-xl-6">
                                            <div className="donate-now__personal-info-input">
                                                <input 
                                                type="text" 
                                                placeholder="First name" 
                                                name="firstName"  
                                                value={firstName} 
                                                onChange={(e) => setInputFirstName(e.target.value)} 
                                                />
                                            </div>
                                        </div>
                                        <div className="col-xl-6">
                                            <div className="donate-now__personal-info-input">
                                                <input 
                                                type="text" 
                                                placeholder="Last name" 
                                                name="lastName" 
                                                value={lastName} 
                                                onChange={(e) => setInputLastName(e.target.value)} 
                                                 />
                                            </div>
                                        </div>
                                        <div className="col-xl-6">
                                            <div className="donate-now__personal-info-input">
                                                <input 
                                                type="email" 
                                                placeholder="Email address" 
                                                name="email"  
                                                value={email} 
                                                onChange={(e) => setInputEmail(e.target.value)} 
                                                />
                                            </div>
                                        </div>
                                        <div className="col-xl-6">
                                            <div className="donate-now__personal-info-input">
                                                <input 
                                                type="text" 
                                                placeholder="Phone" 
                                                name="phone"  
                                                value={phone} 
                                                onChange={(e) => setInputPhone(e.target.value)} 
                                                />
                                            </div>
                                        </div>
                                        <div className="col-xl-6">
                                            <div className="donate-now__personal-info-input">
                                                <input 
                                                type="text" 
                                                placeholder="Address" 
                                                name="address"  
                                                value={address} 
                                                onChange={(e) => setInputAddress(e.target.value)} 
                                                 />
                                            </div>
                                        </div>  
                                       
                                    </div>
                                     <div className="row">
                                        <div className="col-xl-12">
                                            <div className="donate-now__personal-info-input donate-now__personal-info-message-box">
                                                <textarea 
                                                name="comment" 
                                                placeholder="Write message"  
                                                value={comment} 
                                                onChange={(e) => setInputComment(e.target.value)} 
                                                ></textarea>
                                            </div>
                                        </div>
                                    
                                    </div>   
                                   <br />
                                    <div className="donate-now__payment-info-btn-box">
                                    <button type="submit" className="thm-btn donate-now__payment-info-btn">Donate
                                                now</button>
                                        
                                    </div>
                                </form>
                            </div>
                                
                        </div>
                   

        </div>
  );
}
export default DonationForm;
