// import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import HeaderTab from '../comp/HeaderTab';
import SliderTab from '../comp/SliderTab';
import AboutTab from '../comp/AboutTab';
import VolunteerTab from '../comp/VolunteerTab';
import FooterTab from '../comp/FooterTab';
import TestimonialTab from '../comp/TestimonialTab';
import DonationTab from '../comp/DonationTab';
import BecomeVolunteerTab from '../comp/BecomeVolunteerTab';
import EventTab from '../comp/EventTab';
import GalleryTab from '../comp/GalleryTab';
import FaqTab from '../comp/FaqTab';
import FounderProfile from '../comp/FounderProfile';




function Home() {
  return (

    <div className="custom-cursor">
        
        <Helmet>
        <title>TynaChyomaa Charitable Foundation: Nurturing Hope and Empowerment</title>
        <meta name="description" content="Discover how TynaChyomaa Charitable Foundation is making a positive impact by providing care for orphans, empowering youth, offering vocational training, and supporting wido" />
        <meta name="keywords" content="TynaChyomaa, charitable foundation, orphans, youth empowerment, vocational education, widows, community development community empowerment, education,scholarships,literacy,youth development." />
      </Helmet>

          {/* <PreloaderTab /> */}
    <HeaderTab />
    <SliderTab /> 
    <AboutTab />
    <FounderProfile /> 
    <BecomeVolunteerTab />
    <EventTab />
    <VolunteerTab />
    <TestimonialTab /> 
    <br />
    <DonationTab />
    <GalleryTab />
    <FaqTab />
    <FooterTab />
{/* 
    </div>/.page-wrapper */}


    <div className="mobile-nav__wrapper">
        <div className="mobile-nav__overlay mobile-nav__toggler"></div>
        {/* /.mobile-nav__overlay */}
        <div className="mobile-nav__content">
            <span className="mobile-nav__close mobile-nav__toggler"><i className="fa fa-times"></i></span>

            <div className="logo-box">
                <a href="index.html" aria-label="logo image"><img src="assets/images/resources/logo-2.png" width="143" alt="" /></a>
            </div>
            {/* /.logo-box */}
            <div className="mobile-nav__container"></div>
            {/* /.mobile-nav__container */}

            <ul className="mobile-nav__contact list-unstyled">
                <li>
                    <i className="fa fa-envelope"></i>
                    <a href="mailto:needhelp@packageName__.com">needhelp@oxpins.com</a>
                </li>
                <li>
                    <i className="fa fa-phone-alt"></i>
                    <a href="tel:666-888-0000">666 888 0000</a>
                </li>
            </ul>{/* /.mobile-nav__contact */}
            <div className="mobile-nav__top">
                <div className="mobile-nav__social">
                    <a href="https://facebook.com/" className="fab fa-twitter"> </a>
                    <a href="https://facebook.com/" className="fab fa-facebook-square"> </a>
                    <a href="https://facebook.com/" className="fab fa-pinterest-p"> </a>
                    <a href="https://facebook.com/" className="fab fa-instagram"> </a>
                </div>{/* /.mobile-nav__social */}
            </div>{/* /.mobile-nav__top */}



        </div>
        {/* /.mobile-nav__content */}
    </div>
    {/* /.mobile-nav__wrapper */}

    <div className="search-popup">
        <div className="search-popup__overlay search-toggler"></div>
        {/* /.search-popup__overlay */}
        <div className="search-popup__content">
            <form action="#">
                <label htmlFor="search" className="sr-only">search here</label>{/* /.sr-only */}
                <input type="text" id="search" placeholder="Search Here..." />
                <button type="submit" aria-label="search submit" className="thm-btn">
                    <i className="icon-magnifying-glass"></i>
                </button>
            </form>
        </div>
        {/* /.search-popup__content */}
    </div>
    {/* /.search-popup */}

    <a href="/" data-target="html" className="scroll-to-target scroll-to-top"><i className="icon-up-arrow"></i> </a>

{/* 
    <script src="assets/vendors/jquery/jquery-3.6.0.min.js"></script>
    <script src="assets/vendors/bootstrap/js/bootstrap.bundle.min.js"></script>
    <script src="assets/vendors/jarallax/jarallax.min.js"></script>
    <script src="assets/vendors/jquery-ajaxchimp/jquery.ajaxchimp.min.js"></script>
    <script src="assets/vendors/jquery-appear/jquery.appear.min.js"></script>
    <script src="assets/vendors/jquery-circle-progress/jquery.circle-progress.min.js"></script>
    <script src="assets/vendors/jquery-magnific-popup/jquery.magnific-popup.min.js"></script>
    <script src="assets/vendors/jquery-validate/jquery.validate.min.js"></script>
    <script src="assets/vendors/nouislider/nouislider.min.js"></script>
    <script src="assets/vendors/odometer/odometer.min.js"></script>
    <script src="assets/vendors/swiper/swiper.min.js"></script>
    <script src="assets/vendors/tiny-slider/tiny-slider.min.js"></script>
    <script src="assets/vendors/wnumb/wNumb.min.js"></script>
    <script src="assets/vendors/wow/wow.js"></script>
    <script src="assets/vendors/isotope/isotope.js"></script>
    <script src="assets/vendors/countdown/countdown.min.js"></script>
    <script src="assets/vendors/owl-carousel/owl.carousel.min.js"></script>
    <script src="assets/vendors/bxslider/jquery.bxslider.min.js"></script>
    <script src="assets/vendors/bootstrap-select/js/bootstrap-select.min.js"></script>
    <script src="assets/vendors/vegas/vegas.min.js"></script>
    <script src="assets/vendors/jquery-ui/jquery-ui.js"></script>
    <script src="assets/vendors/timepicker/timePicker.js"></script>
    <script src="assets/vendors/circleType/jquery.circleType.js"></script>
    <script src="assets/vendors/circleType/jquery.lettering.min.js"></script>




    {/* template js */}
    {/* <script src="assets/js/oxpins.js"></script> */}
</div>

  );
}
export default Home;
