import React from 'react';
// import { Link } from 'react-router-dom';
import SidebarTab from './SidebarTab'; 


function DonationDetails() {
  return (
    <div>

{/* <!--Donation Details Start--> */}
<section class="donation-details">
            <div class="container">
                <div class="row">
                    <div class="col-xl-8 col-lg-7">
                        <div class="donation-details__left">
                            <div class="donation-details__top">
                                <div class="donation-details__img">
                                    <img src="assets/images/gallery/don-larg.jpeg" alt="" />
                                    <div class="donation-details__date">
                                        <p>Food Support</p>
                                    </div>
                                </div>
                                <div class="donation-details__content">
                            <h3 class="donation-details__title"> Ignite Hope, Transform Lives </h3>
                                    <p class="donation-details__text">
                                    Can you imagine a world where hunger and malnutrition is eradicated? A world where every child has access to quality education, and every family lives with dignity. This is the world we're building, and your compassion is the fuel that drives us forward. </p>

                                    <p class="donation-details__text">
Your generosity is the lifeline for countless individuals facing poverty and hardship. With your support, we can provide life-saving food rations, educational supplies, and healthcare services. Every dollar donated brings us closer to creating a brighter future for rural communities. <br />  <br /> </p>

<p class="donation-details__text">

How Your Donation Makes a Difference:<br /> 

$25 can provide a nutritious meal for a family of five.<br />
$50 can sponsor a child's school uniform and books.<br />
$100 can support a community garden project.<br /><br /> 
 

Thank you for considering a donation to Tynachyomaa Charitable Foundation. Your kindness will not only change lives but also inspire hope for generations to come.

                                         </p>
                                </div>
                            </div>
                            <div class="donation-details__donate">
                                <div class="donation-details__donate-shape" style={{backgroundImage: 'url(assets/images/shapes/donation-details-donate-shape.png)' }}>
                                </div>
                                <div class="donation-details__donate-left">
                                    <ul class="list-unstyled donation-details__donate-list">
                                        <li>
                                            <div class="icon">
                                                <span class="icon-solidarity"></span>
                                            </div>
                                            <div class="text">
                                                <span>Raised</span>
                                                <p>$1000</p>
                                            </div>
                                        </li>
                                        <li>
                                            <div class="icon">
                                                <span class="icon-target-1"></span>
                                            </div>
                                            <div class="text">
                                                <span>Goal</span>
                                                <p>$30,000</p>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                                <div class="donation-details__donate-btn">
                                    <a href="DonateNow" class="thm-btn">Donate now</a>
                                </div>
                            </div>
                            {/* <div class="donation-details__summary">
                                <h3 class="donation-details__summary-title">Summary</h3>
                                <p class="donation-details__summary-text-1">Lorem Ipsum has been the industry's standard
                                    dummy text ever since the 1500s, when an unknown printer took a galley of type and
                                    scrambled it to make a type simen book.</p>
                                <ul class="list-unstyled donation-details__summary-list">
                                    <li>
                                        <div class="icon">
                                            <span class="fa fa-check"></span>
                                        </div>
                                        <div class="text">
                                            <p>Nsectetur cing do not elit.</p>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="icon">
                                            <span class="fa fa-check"></span>
                                        </div>
                                        <div class="text">
                                            <p>Suspe ndisse suscipit sagittis in leo.</p>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="icon">
                                            <span class="fa fa-check"></span>
                                        </div>
                                        <div class="text">
                                            <p>Entum estibulum dignissim lipsm posuere.</p>
                                        </div>
                                    </li>
                                </ul>
                                <p class="donation-details__summary-text-2">Lorem Ipsum is simply dummy text of the
                                    printing and typesetting industry. orem Ipsum has been the industry's standard dummy
                                    text ever since the when an unknown printer took a galley of type and scrambled it
                                    to make a type specimen book.</p>
                        //     </div>  */} 
                          </div>
                    </div>
                  
          
                <SidebarTab />

                </div>
            </div>
        </section>
        {/* <!--Donation Details End--> */}
        </div>
  );
}
export default DonationDetails;
