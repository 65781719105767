// import { Link } from 'react-router-dom';
import HeaderTab from '../comp/HeaderTab'; 
import { Helmet } from 'react-helmet';
import FooterTab from '../comp/FooterTab'; 
import React, { useState } from 'react';
import Swal from 'sweetalert2';


function BecomeVolunteer() { 

    function displaySuccessDialog(title,message) { 
        Swal.fire({
            title: `Welcome, ${title}`,
            text: `${message}`,
            icon: 'success',
            confirmButtonText: 'OK'
        }).then(() => {
            window.location.reload();
          });
        }

const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    address: '',
    dob: '',
    occupation: '',
    comment: '',
  });

  
const handleChange = (event) => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value,
      [event.target.email]: event.target.value,
      [event.target.phone]: event.target.value,
      [event.target.address]: event.target.value,
      [event.target.dob]: event.target.value,
      [event.target.occupation]: event.target.value,
      [event.target.comment]: event.target.value, 
    });
  };

const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      const response = await fetch('https://secure.tynachyomaa.com/api/becomevolunteer', 
         {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });
        
      const data = await response.json(); 
      console.log(data) 
      if (data.success)   
        {
        displaySuccessDialog(data.message, 'Thank you for joining us'); 
      } else {
        console.error('Error submitting form'); 
      }
    } catch (error) {
      console.log('Error:', error); 
    }
  };




  return (
    <div>
  <Helmet>
        <title>Join Our Team | Volunteer with TynaChyomaa</title>
        <meta name="description" content="Become part of our compassionate team and make a difference in the lives of those in need. Learn about volunteer opportunities, how to apply, and the impact you can make." />
        <meta name="keywords" content="volunteer, TynaChyomaa, charitable foundation, opportunities, orphans, youth empowerment, vocational education, widows. " />
      </Helmet>
         <HeaderTab />   
        {/* <!--Become Volunteer Page Start--> */}
        <section className="become-volunteer-page">
            <div className="container">
                <div className="section-title text-center">
                    <span className="section-title__tagline">Register yourself with us</span>
                    <h2 className="section-title__title">Let’s join our community to <br /> become a volunteer</h2>
                </div>
                <div className="row">
                    <div className="col-xl-6 col-lg-6">
                        <div className="become-volunteer-page__left">
                            <div className="become-volunteer-page__img">
                                <img src="assets/images/team/join.jpeg" alt="" />
                            </div>
                            <div className="become-volunteer-page__content">
                                <h3 className="become-volunteer-page__title">Join Us in Making a Difference</h3>
                                <p className="become-volunteer-page__text"> Become a part of something extraordinary. Your time and talents can help us create a lasting impact on our community. Together, we can build a brighter future.</p>
                                
                            </div>
                            <div className="become-volunteer__call">
                                <div className="become-volunteer__call-icon">
                                    <span className="icon-chat"></span>
                                </div>
                                <div className="become-volunteer__call-content">
                                    <span>Call Anytime</span>
                                    <p><a href="tel:+2348033419924">  +2348033419924 </a></p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-6 col-lg-6">
                        <div className="become-volunteer-page__right">
                            {/* <h2> Hello  Thank you for joining.</h2> */}
                            <form className="become-volunteer-page__form"  onSubmit={handleSubmit}>
                                <div className="row">
                                    <div className="col-xl-12">
                                        <div className="become-volunteer-page__input">
                                            {/* <input type="text" placeholder="Your Name" name="name" /> */}
                                            <input type="text"  placeholder="Your Name" name="name" value={formData.name} onChange={handleChange} />
     
                                        </div>
                                    </div>
                                    <div className="col-xl-6">
                                        <div className="become-volunteer-page__input">
                                            <input type="email" placeholder="Email Address" name="email"  value={formData.email} onChange={handleChange} />
                                        </div>
                                    </div>
                                    <div className="col-xl-6">
                                        <div className="become-volunteer-page__input">
                                            <input type="text" placeholder="Phone Number" name="phone"  value={formData.phone} onChange={handleChange} />
                                        </div>
                                    </div>
                                    <div className="col-xl-12">
                                        <div className="become-volunteer-page__input">
                                            <input type="text" placeholder="Address" name="address"  value={formData.address} onChange={handleChange} />
                                        </div>
                                    </div>
                                    <div className="col-xl-12">
                                        <div className="become-volunteer-page__input">
                                            <input type="text" placeholder="Date of Birth" name="dob" id="datepicker"  value={formData.dob} onChange={handleChange} />
                                        </div>
                                    </div>
                                    <div className="col-xl-12">
                                        <div className="become-volunteer-page__input">
                                            <input type="text" placeholder="Occupation" name="occupation"  value={formData.occupation} onChange={handleChange}  />
                                        </div>
                                    </div>
                                    <div className="col-xl-12">
                                        <div className="become-volunteer-page__input become-volunteer__message-box">
                                            <textarea name="comment" placeholder="Write a Comment"  value={formData.comment} onChange={handleChange} ></textarea>
                                        </div>
                                        <div className="become-volunteer-page__btn-box">
                                            <button type="submit" className="thm-btn become-volunteer-page__btn">Send a
                                                message</button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        {/* <!--Become Volunteer Page End--> */}





        <FooterTab />
    </div>
  );
}


export default BecomeVolunteer;
