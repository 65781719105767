// import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import HeaderTab from '../comp/HeaderTab';
import BreadcrumDonate from '../comp/BreadcrumDonate';  
import DonationDetails from '../comp/DonationDetails';  
import DonationTab from '../comp/DonationTab';  
import FooterTab from '../comp/FooterTab';


function Donations() {
  return (
    <div>
      <Helmet>
        <title> Donate | Support Our Cause: Nurturing Hope </title>
        <meta name="description" content="Learn how your donations help provide essential care, education, and support to orphans, youth, and widows. Make a difference today." />
        <meta name="keywords" content="donate, TynaChyomaa, charitable foundation, support, orphans, youth empowerment, vocational education, widows " />
      </Helmet>
 
         <HeaderTab />
         <BreadcrumDonate />
         <DonationDetails  />
        <DonationTab />
        <FooterTab />
    </div>
  );
}
export default Donations;
