import { Helmet } from 'react-helmet';
// import { Link } from 'react-router-dom';
import HeaderTab from '../comp/HeaderTab';
import FounderProfile from '../comp/FounderProfile';
import AboutTab from '../comp/AboutTab';
import FooterTab from '../comp/FooterTab';
import BreadcrumAbout from '../comp/BreadcrumAbout';
import TestimonialTab from '../comp/TestimonialTab';
import BecomeVolunteerTab from '../comp/BecomeVolunteerTab';
import FaqTab from '../comp/FaqTab';


function About() {
  return (
    <div>
       <Helmet>
        <title>About TynaChyomaa Charitable Foundation: A Beacon of Hope</title>
        <meta name="description" content="Learn more about our commitment to providing holistic care and support to orphans, youth, and widows. Discover our history, goals, and the dedicated team behind our mission." />
        <meta name="keywords" content="TynaChyomaa, charitable foundation, mission, vision, team, orphans, youth empowerment, vocational education, widows, healthcare,"   />
      </Helmet>
      {/* <h1>About Us</h1>
      <Link to="/contact">Contact Us</Link> */}
         <HeaderTab />
         <BreadcrumAbout />
         <AboutTab />
         <FounderProfile /> 
         <TestimonialTab />
         <BecomeVolunteerTab />
         <FaqTab />

         <FooterTab />
    </div>
  );
}
export default About;
