import { Helmet } from 'react-helmet';
// import { Link } from 'react-router-dom';
import HeaderTab from '../comp/HeaderTab';
import BreadcrumContact from '../comp/BreadcrumContact';
import ContactFormTab from '../comp/ContactFormTab';
import ContactDetails from '../comp/ContactDetails';
import ContactGooglemapTab from '../comp/ContactGooglemapTab';
import FooterTab from '../comp/FooterTab';

function Contact() {
  return (
    <div>
       <Helmet>
        <title> Contact TynaChyomaa Charitable Foundation </title>
        <meta name="description" content="Find our contact information, including address, phone number, and email. Use our contact form to get in touch and learn more about our initiatives." />
        <meta name="keywords" content="contact, TynaChyomaa, charitable foundation, feedback, support, orphans, youth empowerment, vocational education, widows" />
      </Helmet>
      {/* <h1>About Us</h1>
      <Link to="/contact">Contact Us</Link> */}
         <HeaderTab />
         <BreadcrumContact />
         <ContactFormTab />
         <ContactDetails />
         <ContactGooglemapTab />
<FooterTab />
    </div>
  );
}
export default Contact;