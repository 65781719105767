// import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
// import { useState } from 'react';
import HeaderTab from '../comp/HeaderTab';
// import BreadcrumDonateForm from '../comp/BreadcrumDonateForm';  
import DonationForm from '../comp/DonationForm';   
import SidebarTab from '../comp/SidebarTab';  
// import DonationTab from '../comp/DonationTab';  
// import Select from 'react-select';
import FooterTab from '../comp/FooterTab';

// const options = [
//     { value: 'chocolate', label: 'Chocolate' },
//     { value: 'strawberry', label: 'Strawberry' },
//     { value: 'vanilla', label: 'Vanilla' },
//   ];
//   const [selectedOption, setSelectedOption] = useState(null);
//   const change  = []

function DonateNow() {
  return (
    <div> 
       <Helmet>
        <title>Donate Now | Empower Lives</title>
        <meta name="keywords" content="donate, TynaChyomaa, charitable foundation, support, donate, TynaChyomaa, charitable foundation, support, orphans, youth empowerment, vocational education, widows" />
        <meta name="description" content="A clear and concise page guiding visitors through the donation process. Choose your preferred donation method and make a meaningful contribution." />
      </Helmet>
         <HeaderTab /> 
        <section className="donate-now">
            <div className="container">
                <div className="row">
                   

                    <DonationForm />
                    <SidebarTab />

 
                </div>
            </div>
        </section>
       




        <FooterTab />
    </div>
  );
}
export default DonateNow;
