import React from 'react';
// import { Link } from 'react-router-dom';

function FaqTab() {
  return (
    <div>
{/*FAQ One Start*/}
<section className="faq-one">
            <div className="faq-one-shape-1" style={{backgroundImage: 'url(assets/images/shapes/faq-one-shape-1.png)' }}></div>
            <div className="faq-one-bg" style={{backgroundImage: 'url(assets/images/backgrounds/faq-one-bg.png)' }}></div>
            <div className="container">
                <div className="row">
                    <div className="col-xl-6 col-lg-6">
                        <div className="faq-one__left">
                            <div className="section-title text-left">
                                <span className="section-title__tagline">Recently asked questions</span>
                                <h2 className="section-title__title">People are frequently asking some questions from us
                                </h2>
                            </div>
                            <p className="faq-one__text-1">We've compiled a list of commonly asked questions to help you learn more about our foundation and our work. If you can't find the answer you're looking for, please don't hesitate to contact us.</p>
                            <a href="contact" className="thm-btn faq-one__btn"> Ask more questions </a>
                        </div>
                    </div>
                    <div className="col-xl-6 col-lg-6">
                        <div className="faq-one__right">
                            <div className="accrodion-grp" data-grp-name="faq-one-accrodion">

                                <div className="accrodion active">
                                    <div className="accrodion-title">
                                        <h4> What is Tynachyomaa Charitable Foundation’s mission? </h4>
                                    </div>
                                    <div className="accrodion-content">
                                        <div className="inner">
                                            <p> 
Our mission is to improve the lives of people in rural areas by providing essential food support and promoting sustainable livelihoods.</p>
                                        </div>{/* /.inner */}
                                    </div>
                                </div>

<div className="accrodion">
    <div className="accrodion-title">
        <h4> What is the impact of my donation? </h4>
    </div>
    <div className="accrodion-content">
        <div className="inner">
            <p> 

Your donation helps provide essential meals for hungry families, support local farmers, and strengthen rural communities.</p>
        </div>{/* /.inner */}
    </div>
</div>



<div className="accrodion">
                                    <div className="accrodion-title">
                                        <h4> How can I donate to Tynachyomaa Charitable Foundation?</h4>
                                    </div>
                                    <div className="accrodion-content">
                                        <div className="inner">
                                            <p> 

You can donate online through our website, by check, or by contacting our office directly.</p>
                                        </div>{/* /.inner */}
                                    </div>
                                </div>

                                <div className="accrodion">
                                    <div className="accrodion-title">
                                        <h4> How can I contact Tynachyomaa Charitable Foundation? </h4>
                                    </div>
                                    <div className="accrodion-content">
                                        <div className="inner">
                                            <p> 
                                            

You can contact us by phone, email, or through our contact form on the website.</p>
                                        </div>{/* /.inner */}
                                    </div>
                                </div>


                                <div className="accrodion">
                                    <div className="accrodion-title">
                                        <h4> Where does the money I donate go? </h4>
                                    </div>
                                    <div className="accrodion-content">
                                        <div className="inner">
                                            <p> 

Your donations directly support our rural feeding programs, community gardens, and food distribution networks. We provide regular updates on how your contributions are making a difference.</p>
                                        </div>{/* /.inner */}
                                    </div>
                                </div>


                                <div className="accrodion last-chiled">
                                    <div className="accrodion-title">
                                        <h4> How can I get involved with Tynachyomaa Charitable Foundation? </h4>
                                    </div>
                                    <div className="accrodion-content">
                                        <div className="inner">
                                            <p>
                                            

There are many ways to get involved! You can donate, volunteer, advocate for our cause, or become a partner.
                                            </p>
                                        </div>{/* /.inner */}
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        {/*FAQ One End*/}



 

        </div>
  );
}
export default FaqTab;
