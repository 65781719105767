import React from 'react';
// import { Link } from 'react-router-dom';

function ContactGooglemapTab() {
  return (
    <div>
     {/* <!--Google Map Start--> */}
        <section className="google-map google-map-two">
            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d31771.37920077334!2d7.047520744830258!3d5.504131964622747!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1042f7e23304a529%3A0x1f5deb19a56081e7!2sOwerri%20North%20LGA!5e0!3m2!1sen!2sng!4v1723233203813!5m2!1sen!2sng" className="google-map__one" allowFullScreen=""  loading="lazy" referrerPolicy="no-referrer-when-downgrade" title="LocationMap" ></iframe>
 

        </section>
        {/* <!--Google Map End--> */}

        </div>
  );
}
export default ContactGooglemapTab;
