import React, { useState } from 'react';
// import { useNavigate } from 'react-router-dom';
// import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';


function ContactFormTab() {
  //  const navigate = useNavigate();
    function displaySuccessDialog(title,message) { 
        Swal.fire({
            title: `${title}`,
            text: `${message}`,
            icon: 'success',
            confirmButtonText: 'OK'
        }).then(() => {
            window.location.reload();
          });
        }

const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    subject: '', 
    comment: '',
  });

  
const handleChange = (event) => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value,
      [event.target.email]: event.target.value,
      [event.target.phone]: event.target.value,
      [event.target.subject]: event.target.value, 
      [event.target.comment]: event.target.value, 
    });
  };

const handleSubmit = async (event) => {
    event.preventDefault();
// console.log(formData)
    try {
      const response = await fetch('https://secure.tynachyomaa.com/api/contact', 
         {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });
        
      const data = await response.json(); 
    //   console.log(response) 
    //   console.log(data) 
      if (data.success)   
        {
        displaySuccessDialog(data.message, 'Your message has been successfully sent.'); 
      //  navigate('/'); 

      } else {
        console.error('Error submitting form'); 
      }
    } catch (error) {
      console.log('Error:', error); 
    }
  };



  return (
    <div>

{/* <!--Contact Three Start--> */}
        <section className="contact-three">
            <div className="contact-three-shape" style={{backgroundImage: 'url(assets/images/shapes/contact-three-shape.png)'}}></div>
            <div className="container">
                <div className="section-title text-center">
                    <span className="section-title__tagline">Contact  us</span>
                    <h2 className="section-title__title">Feel free to write us <br /> anytime</h2>
                </div>
                <div className="contact-page__form-box">
                    <form   className="contact-page__form contact-form-validated"   onSubmit={handleSubmit} >
                        <div className="row">
                            <div className="col-xl-6">
                                <div className="contact-form__input-box">
                                    <input type="text" placeholder="Your name" name="name" value={formData.name} onChange={handleChange} />
                                </div>
                            </div>
                            <div className="col-xl-6">
                                <div className="contact-form__input-box">
                                    <input type="email" placeholder="Email address" name="email" value={formData.email} onChange={handleChange} />
                                </div>
                            </div>
                            <div className="col-xl-6">
                                <div className="contact-form__input-box">
                                    <input type="text" placeholder="Phone" name="phone" value={formData.phone} onChange={handleChange} />
                                </div>
                            </div>
                            <div className="col-xl-6">
                                <div className="contact-form__input-box">
                                    <input type="text" placeholder="Subject" name="subject" value={formData.subject} onChange={handleChange} />
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-xl-12">
                                <div className="contact-form__input-box text-message-box">
                                    <textarea name="comment" placeholder="Write a message" value={formData.comment} onChange={handleChange} ></textarea>
                                </div>
                                <div className="contact-form__btn-box">
                                    <button type="submit" className="thm-btn contact-form__btn">Send a message</button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </section>
            {/* <!--Contact Three End--> */}

        </div>
  );
}
export default ContactFormTab;
