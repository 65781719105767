import React from 'react';
// import { Link } from 'react-router-dom';

function FounderProfile() {
  return (
    <div>
        <section className="product-details">
            <div className="container">
                <div className="row">
                    <div className="col-lg-6 col-xl-6">
                        <div className="product-details__img">
                            <img src="assets/images/team/founder.jpeg" alt="" />
                        </div>
                    </div>
                    <div className="col-lg-6 col-xl-6">
                        <div className="product-details__top">
                            <h3 className="product-details__title"   style={{ marginLeft: '-10px' }}> 
                                The Founder, 
                                <span  style={{ marginLeft: '5px' }}>Tynachyomaa Charitable Foundation</span> 
                                </h3>
                        </div>
                        {/* <div className="product-details__reveiw">
                            <i className="fa fa-star"></i>
                            <i className="fa fa-star"></i>
                            <i className="fa fa-star"></i>
                            <i className="fa fa-star"></i>
                            <i className="fa fa-star"></i>
                            <span>2 customer reviews</span>
                        </div> */}
                        <div className="product-details__content">
                            <p className="product-details__content-text1"  style={{ fontSize: '0.9em', textAlign: 'justify' }}>
                            The Tynachiomaa Charitable Foundation was founded by Barr. Justina Chioma. A Barrister and a solicitor of the Supreme court of Nigeria, she is also a holder of Bachelors of Science(Bsc) and Master of Science (Msc) degrees respectively. Working as the head of Admin at Ensiec before relocating to Republic of Ireland with her lovely family. 
                            </p>
                            <p className="product-details__content-text1"  style={{ fontSize: '0.9em', textAlign: 'justify'  }}>
Barr.Tina as widely known has been engaged in a community developments and philanthropic activities ranging from Scholarship schemes to less privileged, especially the orphans, Medical outreach to the communities, youths empowerment, Vocational education and empowerment, caring for the widows and orphans. 
</p>
                            <p className="product-details__content-text1"  style={{ fontSize: '0.9em' , textAlign: 'justify' }}>
Many people admire the work Barr. Tina is doing with her non profit organisation. She is dedicating to such important charitable causes, bringing smiles on people faces is incredibly commendable. The impact she is making in the communities is so valuable and meaningful. I can tell this is not a job for her, but a true passion and commitment to helping others. Please know that your efforts are deeply appreciated and you are making  a real difference in people's lives. Keep up the amazing work. The world needs more people like Barr. Tina.
 </p>

                            {/* <p className="product-details__content-text2">REF. 4231/406 <br />
                                Available in store </p> */}
                        </div>

                        {/* <div className="product-details__quantity">
                            <h3 className="product-details__quantity-title">Quantity</h3>
                            <div className="quantity-box">
                                <button type="button" className="sub"><i className="fa fa-minus"></i></button>
                                <input type="number" id="1" value="1" />
                                <button type="button" className="add"><i className="fa fa-plus"></i></button>
                            </div>
                        </div> */}


                        <div className="product-details__buttons">
                            <div className="product-details__buttons-1">
                                <a href="contact" className="thm-btn"> Contact me</a>
                            </div>

                            {/* <div className="product-details__buttons-2">
                                <a href="cart.html" className="thm-btn">Add to cart</a>
                            </div> */}
                        </div>
                        {/* <div className="product-details__social">
                            <div className="title">
                                <h3>Share with friends</h3>
                            </div>
                            <div className="product-details__social-link">
                                <a href="https://facebook.com/"><span className="fab fa-twitter"></span></a>
                                <a href="https://facebook.com/"><span className="fab fa-facebook"></span></a>
                                <a href="https://facebook.com/"><span className="fab fa-pinterest-p"></span></a>
                                <a href="https://facebook.com/"><span className="fab fa-instagram"></span></a>
                            </div>
                        </div> */}
                    </div>
                </div>
            </div>
        </section>

        </div>
  );
}
export default FounderProfile;
