import React from 'react'; 
import { BrowserRouter, Routes, Route} from 'react-router-dom';
import Home from './pages/Home';
import About from './pages/About'; 
import Events from './pages/Events';
import Gallery from './pages/Gallery';
import Contact from './pages/Contact';
import Donations from './pages/Donations';
import DonateNow from './pages/DonateNow';
import BecomeVolunteer from './pages/BecomeVolunteer';
import ThankYou from './pages/ThankYou';

function App() {
  return (
    <BrowserRouter> 
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/About" element={<About />} /> 
        <Route path="/Events" element={<Events />} />
        <Route path="/Gallery" element={<Gallery />} />
        <Route path="/BecomeVolunteer" element={<BecomeVolunteer />} />
        <Route path="/Contact" element={<Contact />} />
        <Route path="/Donations" element={<Donations />} />  
        <Route path="/DonateNow" element={<DonateNow />} />  
        <Route path="/ThankYou" element={<ThankYou />} /> 
      </Routes>
    </BrowserRouter>
  );
}

export default App;
