import React from 'react';
// import { Link } from 'react-router-dom';

function FooterTab() {
  return (
    <div>
      {/*Site Footer Start*/}
      <footer className="site-footer">
            <div className="site-footer-bg" style={{backgroundImage: 'url(assets/images/backgrounds/site-footer-bg.jpg)' }} >
            </div>
            <div className="site-footer__top">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-4 col-lg-6 col-md-6 wow fadeInUp" data-wow-delay="100ms">
                            <div className="footer-widget__column footer-widget__about">
                                <div className="footer-widget__about-logo">
                                    <a href="/"><img src="assets/images/resources/footer-logo.png" alt="" /></a>
                                </div>
                                <div className="footer-widget__about-text-box">
                                    <p className="footer-widget__about-text">
                                    TynaChyomaa Charitable  Foundation, a dedicated non-profit entity committed to transforming lives and fostering sustainable development in underserved communities. </p>
                                </div>
                                <div className="footer-widget__btn">
                                    <a href="donations"> <span className="fa fa-heart"></span>Donate now</a>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-2 col-lg-6 col-md-6 wow fadeInUp" data-wow-delay="200ms">
                            <div className="footer-widget__column footer-widget__links clearfix">
                                <h3 className="footer-widget__title">Links</h3>
                                <ul className="footer-widget__links-list list-unstyled clearfix">
                                    <li><a href="about">About us</a></li> 
                                    <li><a href="events">Latest News</a></li>
                                    <li><a href="Gallery"> Gallery</a></li>
                                    <li><a href="donation">Donations</a></li>
                                    <li><a href="contact">Contact Us</a></li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-xl-2 col-lg-6 col-md-6 wow fadeInUp" data-wow-delay="300ms">
                            <div className="footer-widget__column footer-widget__non-profit clearfix">
                                <h3 className="footer-widget__title">Our Programs</h3>
                                <ul className="footer-widget__non-profit-list list-unstyled clearfix">
                                    <li><a href="events">Educational Programs</a></li>
                                    <li><a href="events">Healthcare Programs</a></li>
                                    <li><a href="events">Poverty Alleviation Programs</a></li>
                                    <li><a href="events">Agricultural Development Programs</a></li>
                                    <li><a href="events">Technology and Innovation Programs</a></li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-xl-4 col-lg-6 col-md-6 wow fadeInUp" data-wow-delay="400ms">
                            <div className="footer-widget__column footer-widget__contact">
                                <h3 className="footer-widget__title">Contact</h3>

                                <p className="footer-widget__contact-text"> 
                                  Plot 299 Uratta East layout, MCC Road, Owerri North LGA, Imo state .
                                </p>

                                <p className="footer-widget__contact-text"> 
                                     5C Michael Ogun street, GRA. Ikeja - Lagos State.
                                </p>

                                <p className="footer-widget__contact-text"> 
                                13 Chestnut Avenue eskerhills portlaoise, County Laois, Republic of Ireland.
                                </p>
 


                                <ul className="list-unstyled footer-widget__contact-list">
                                    <li>
                                        <div className="icon">
                                            <i className="fa fa-envelope"></i>
                                        </div>
                                        <div className="text">
                                        <p><a href="mailto:info@TynaChyomaa.com ">info@TynaChyomaa.com</a></p>
                                        <p><a href="mailto:tinachiomaa@gmail.com  ">tinachiomaa@gmail.com </a></p>
                                            
                                        </div>
                                    </li>
                                    <li>
                                        <div className="icon">
                                            <i className="fas fa-phone-alt"></i>
                                        </div>
                                        <div className="text">
                                            <p><a href="tel:+2348033419924">  +2348033419924  </a></p>
                                            <p><a href="tel:+353870906862">   +353870906862</a></p>
                                        </div>
                                    </li>
                                </ul>
                                <div className="site-footer__social">
                                    <a href="https://facebook.com/"><i className="fab fa-twitter"></i></a>
                                    <a href="https://facebook.com/"><i className="fab fa-facebook"></i></a>
                                    <a href="https://facebook.com/"><i className="fab fa-pinterest-p"></i></a>
                                    <a href="https://facebook.com/"><i className="fab fa-instagram"></i></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="site-footer__bottom">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-12">
                            <div className="site-footer__bottom-inner">
                                <p className="site-footer__bottom-text">© All Copyright 2024 TynaChyomaa | Powered by <a href="https://skyfent.com">Skyfent Developers </a>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
        {/*Site Footer End*/}

        </div>
  );
}
export default FooterTab;
