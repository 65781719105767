import React from 'react'; 

function AboutTab() {
  return (
    <div>

        {/*About One Start*/}
        <section className="about-one">
            <div className="about-one__shape-box-1">
                <div className="about-one__shape-1" style={{backgroundImage: 'url(assets/images/shapes/about-one-shape-1.png)' }}></div>
            </div>
            <div className="container">
                <div className="row">
                    <div className="col-xl-6">
                        <div className="about-one__left">
                            <div className="about-one__img-box wow slideInLeft" data-wow-delay="100ms" data-wow-duration="2500ms">
                                <div className="about-one__img">
                                    <img src="assets/images/resources/about-one-img-1.jpg" alt="" />
                                </div>
                                <div className="about-one__img-border"></div>
                                  {/* <div className="about-one__curved-circle-box">
                                      <div className="curved-circle">
                                     <span className="curved-circle--item">
                                            25 YEARS EXPERIENCE OXPINS CHARITY CENTER
                                        </span>   
                                    </div>  
                                    <div className="about-one__curved-circle-icon">
                                        <img src="assets/images/icon/curved-circle-icon.png" alt="" />
                                    </div>
                                </div>*/}
                                <div className="about-one__shape-2 zoom-fade">
                                    <img src="assets/images/shapes/about-one-shape-2.png" alt="" />
                                </div>
                                <div className="about-one__shape-3 float-bob-y">
                                    <img src="assets/images/shapes/about-one-shape-3.png" alt="" />
                                </div>
                                <div className="about-one__shape-4 zoominout">
                                    <img src="assets/images/shapes/about-one-shape-4.png" alt="" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-6">
                        <div className="about-one__right">
                            <div className="section-title text-left">
                                <span className="section-title__tagline">Welcome to Tynachyomaa Charitable Foundation.</span>
                                <h2 className="section-title__title"> Together, we create a brighter future.</h2>
                            </div>
                            <p className="about-one__text">
                            Tynachyomaa Charitable Foundation is dedicated to empowering underprivileged children through quality education and creating a brighter future for their communities.
                            <br />
                            Our vision is a world where every child has equal access to education and opportunities to reach their full potential. Guided by our values of compassion, integrity, excellence, collaboration, and sustainability, we strive to provide holistic educational support, including mentorship, skill development, and community engagement. Together, we can create a future where every child has the chance to succeed.
                             </p>
                          {/* <div className="about-one__fund">
                                <p className="about-one__fund-text">Helped fund <span>24,537</span> Projects in
                                    <span>24</span> Countries, Benefiting over <br /> <span>8.2</span> Million people.</p>
                            </div> */}
                            <br />
                            <ul className="list-unstyled about-one__points">
                                <li>
                                    <div className="icon">
                                        <span className="icon-volunteer"></span>
                                    </div>
                                    <div className="text">
                                        <h5><a href="about">Join our team</a></h5>
                                        <p>Join forces to make a significant impact. Explore collaboration opportunities.</p>
                                    </div>
                                </li>
                                <li>
                                    <div className="icon">
                                        <span className="icon-solidarity"></span>
                                    </div>
                                    <div className="text">
                                        <h5><a href="donations">Start donating</a></h5>
                                        <p>Your generous donation will support a child's education, feeding or health.

.</p>
                                    </div>
                                </li>
                            </ul>
                            <a href="donations" className="thm-btn about-one__btn">Discover More</a>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        {/*About One End*/}
        </div>
  );
}
export default AboutTab;
