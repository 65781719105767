import React from 'react';
// import { Link } from 'react-router-dom';

function TestimonialTab() {
  return (
    <div>
      {/*Testimonial One Start*/}
      <section className="testimonial-one">
            <div className="testimonial-one-bg jarallax" data-jarallax="" data-speed="0.2" data-imgposition="50% 0%" style={{backgroundImage: 'url(assets/images/backgrounds/testimonial-one-bg.jpg)' }} ></div>
            <div className="container">
                <div className="row">
                    <div className="col-xl-6 col-lg-6">
                        <div className="testimonial-one__left">
                            <div className="section-title text-left">
                                <span className="section-title__tagline">Transformative Stories</span>
                                <h2 className="section-title__title">Transforming Lives Through Nutrition</h2>
                            </div>
                            <p className="testimonial-one__text-1">
                            
                            Discover the impact of your generosity through the inspiring stories of those we serve. Their words speak volumes about the power of your support.</p>
                            <a href="contact" className="thm-btn testimonial-one__btn"> Hear testimonials</a>
                        </div>
                    </div>
                    <div className="col-xl-6 col-lg-6">
                        <div className="testimonial-one__right">
                            <div className="testimonial-one__img-1 zoom-fade">
                                <img src="assets/images/testimonial/hidden.jpg" alt="" />
                            </div>
                            <div className="testimonial-one__img-1 testimonial-one__img-2 zoom-fade">
                                <img src="assets/images/testimonial/hidden.jpg" alt="" />
                            </div>
                            <div className="testimonial-one__img-1 testimonial-one__img-3 zoom-fade">
                                <img src="assets/images/testimonial/hidden.jpg" alt="" />
                            </div>
                            <div className="testimonial-one__img-1 testimonial-one__img-4 zoom-fade">
                                <img src="assets/images/testimonial/hidden.jpg" alt="" />
                            </div>
                            <div className="testimonial-one__carousel owl-carousel owl-theme thm-owl__carousel" data-owl-options='{
                                "loop": true,
                                "autoplay": true,
                                "margin": 50,
                                "nav": true,
                                "dots": false,
                                "smartSpeed": 500,
                                "autoplayTimeout": 10000,
                                "navText": ["<span className=\"icon-left-arrow\"></span>","<span className=\"icon-right-arrow\"></span>"],
                                "responsive": {
                                    "0": {
                                        "items": 1
                                    },
                                    "768": {
                                        "items": 1
                                    },
                                    "992": {
                                        "items": 1
                                    },
                                    "1200": {
                                        "items": 1
                                    }
                                }
                            }'>


                                    <div className="item">
                                    {/*Testimonial One Single Start*/}
                                    <div className="testimonial-one__single">
                                        <div className="testimonial-one__shape-1" style={{backgroundImage: 'url(assets/images/shapes/testimonial-one-shape-1.png)' }}>
                                        </div>
                                        <div className="testimonial-one__client-img">
                                            <img src="assets/images/testimonial/hidden.jpg" alt="" />
                                        </div>
                                        <div className="testimonial-one__client-info">
                                            <h3 className="testimonial-one__client-name"> Mrs. Ukaibe, 60 yrs </h3>
                                            <p className="testimonial-one__client-sub-title">Dignity in Old Age</p>
                                        </div>
                                        <div className="testimonial-one__quote">
                                            <span className="icon-double-quotes"></span>
                                        </div>
                                        <p className="testimonial-one__text-2">As an elderly woman living alone, I often worried about where my next meal would come from. Tynachyomaa's food assistance program has brought me peace of mind and allowed me to live with dignity..</p>
                                    </div>
                                    {/*Testimonial One Single End*/}
                                </div>

                                <div className="item">
                                {/*Testimonial One Single Star  t*/}
                                    <div className="testimonial-one__single">
                                        <div className="testimonial-one__shape-1" style={{backgroundImage: 'url(assets/images/shapes/testimonial-one-shape-1.png)' }}>
                                        </div>
                                        <div className="testimonial-one__client-img">
                                            <img src="assets/images/testimonial/hidden.jpg" alt="" />
                                        </div>
                                        <div className="testimonial-one__client-info">
                                            <h3 className="testimonial-one__client-name">Catherine Acholonu </h3>
                                            <p className="testimonial-one__client-sub-title">A Meal, A Hope</p>
                                        </div>
                                        <div className="testimonial-one__quote">
                                            <span className="icon-double-quotes"></span>
                                        </div>
                                        <p className="testimonial-one__text-2">
                                        Living in a remote village, food scarcity was a constant struggle. Thanks to Tynachyomaa, my family now has access to nutritious meals. My children are healthier, and I can see the difference in their development.


                                        </p>
                                    </div>
                                    {/*Testimonial One Single End*/}
                                </div>

                                <div className="item">
                                    {/*Testimonial One Single Start*/}
                                    <div className="testimonial-one__single">
                                        <div className="testimonial-one__shape-1" style={{backgroundImage: 'url(assets/images/shapes/testimonial-one-shape-1.png)' }}>
                                        </div>
                                        <div className="testimonial-one__client-img">
                                            <img src="assets/images/testimonial/hidden.jpg" alt="" />
                                        </div>
                                        <div className="testimonial-one__client-info">
                                            <h3 className="testimonial-one__client-name"> Ebere Ndukwe </h3>
                                            <p className="testimonial-one__client-sub-title">Education is My Key</p>
                                        </div>
                                        <div className="testimonial-one__quote">
                                            <span className="icon-double-quotes"></span>
                                        </div>
                                        <p className="testimonial-one__text-2">Thanks to Tynachyomaa Charitable Foundation, I was able to complete my education. I'm now working as a teacher, helping other children like me. Your support has changed my life forever.</p>
                                    </div>
                                    {/*Testimonial One Single End*/}
                                </div>
                                
                                <div className="item">
                                    {/*Testimonial One Single Start*/}
                                    <div className="testimonial-one__single">
                                        <div className="testimonial-one__shape-1" style={{backgroundImage: 'url(assets/images/shapes/testimonial-one-shape-1.png)' }}>
                                        </div>
                                        <div className="testimonial-one__client-img">
                                            <img src="assets/images/testimonial/hidden.jpg" alt="" />
                                        </div>
                                        <div className="testimonial-one__client-info">
                                            <h3 className="testimonial-one__client-name">Mercy Ifendu</h3>
                                            <p className="testimonial-one__client-sub-title">
                                            Farming with Confidence </p>
                                        </div>
                                        <div className="testimonial-one__quote">
                                            <span className="icon-double-quotes"></span>
                                        </div>
                                        <p className="testimonial-one__text-2"> The agricultural support from Tynachyomaa has transformed my life. With improved farming techniques and access to quality seeds, I'm producing more food for my family and community. It's a new beginning. </p>
                                    </div>
                                    {/*Testimonial One Single End*/}
                                </div>
                                
                                <div className="item">
                                    {/*Testimonial One Single Start*/}
                                    <div className="testimonial-one__single">
                                        <div className="testimonial-one__shape-1" style={{backgroundImage: 'url(assets/images/shapes/testimonial-one-shape-1.png)' }}>
                                        </div>
                                        <div className="testimonial-one__client-img">
                                            <img src="assets/images/testimonial/hidden.jpg" alt="" />
                                        </div>
                                        <div className="testimonial-one__client-info">
                                            <h3 className="testimonial-one__client-name"> Nkechi Uzo-Uwani</h3>
                                            <p className="testimonial-one__client-sub-title">Food for My Family</p>
                                        </div>
                                        <div className="testimonial-one__quote">
                                            <span className="icon-double-quotes"></span>
                                        </div>
                                        <p className="testimonial-one__text-2">
                                        During the difficult times, Tynachyomaa Charitable Foundation's food assistance was a lifeline for my family. We are so grateful for their support
                                        </p>
                                    </div>
                                    {/*Testimonial One Single End*/}
                                </div>
                                <div className="item">
                                    {/*Testimonial One Single Start*/}
                                    <div className="testimonial-one__single">
                                        <div className="testimonial-one__shape-1" style={{backgroundImage: 'url(assets/images/shapes/testimonial-one-shape-1.png)' }}>
                                        </div>
                                        <div className="testimonial-one__client-img">
                                            <img src="assets/images/testimonial/hidden.jpg" alt="" />
                                        </div>
                                        <div className="testimonial-one__client-info">
                                            <h3 className="testimonial-one__client-name"> Sarah, 20, Mother </h3>
                                            <p className="testimonial-one__client-sub-title"> Empowering Our Community</p>
                                        </div>
                                        <div className="testimonial-one__quote">
                                            <span className="icon-double-quotes"></span>
                                        </div>
                                        <p className="testimonial-one__text-2">
                                        Tynachyomaa's support has helped us establish a community garden. We're not only growing food but also learning valuable skills and creating jobs for young people.
                                        </p>
                                    </div>
                                    {/*Testimonial One Single End*/}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        {/*Testimonial One End*/}






 
        </div>
  );
}
export default TestimonialTab;
