import React from 'react';
// import { Link } from 'react-router-dom'; 

function SidebarTab() {
  return (
    <div className="col-xl-4 col-lg-5">
 
                        <div className="donation-details__sidebar">
                            <div className="donation-details__organizer">
                                <div className="sidebar-shape-1" style={{backgroundImage: 'url(assets/images/shapes/sidebar-shape-1.png)'}}></div>
                                <div className="donation-details__organizer-img">
                                    <img src="assets/images/testimonial/hidden.jpg" alt="" />
                                </div>
                                <div className="donation-details__organizer-content">
                                    <p className="donation-details__organizer-date">CEO / Founder</p>
                                    <p className="donation-details__organizer-title">Tynachyomaa Charitable Foundation</p>
                                    <p className="donation-details__organizer-name">Tina Chioma </p>
                                    <ul className="list-unstyled donation-details__organizer-list">
                                        <li>
                                            <div className="icon">
                                                <span className="fa fa-envelope"></span>
                                            </div>
                                            <div className="text">
                                                <p>founder@Tynachyomaa.com</p>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="icon">
                                                <span className="fas fa-phone-alt"></span>
                                            </div>
                                            <div className="text">
                                                <p>+2348033419924</p>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>

                            {/* <div className="sidebar__post">
                                <div className="sidebar-shape-1" style={{backgroundImage: 'url(assets/images/shapes/sidebar-shape-1.png)' }}></div>
                                <h3 className="sidebar__title">Latest posts</h3>
                                <ul className="sidebar__post-list list-unstyled">
                                    <li>
                                        <div className="sidebar__post-image">
                                            <img src="assets/images/blog/lp-1-1.jpg" alt="" />
                                        </div>
                                        <div className="sidebar__post-content">
                                            <h3>
                                                <span className="sidebar__post-content-meta"><i className="fas fa-user-circle"></i> By admin</span>
                                                <a href="news-details.html">Promoting the rights
                                                    of children</a>
                                            </h3>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="sidebar__post-image">
                                            <img src="assets/images/blog/lp-1-2.jpg" alt="" />
                                        </div>
                                        <div className="sidebar__post-content">
                                            <h3>
                                                <span className="sidebar__post-content-meta"><i className="fas fa-user-circle"></i> By admin</span>
                                                <a href="news-details.html">There are many variations of</a>
                                            </h3>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="sidebar__post-image">
                                            <img src="assets/images/blog/lp-1-3.jpg" alt="" />
                                        </div>
                                        <div className="sidebar__post-content">
                                            <h3>
                                                <span className="sidebar__post-content-meta"><i className="fas fa-user-circle"></i> By admin</span>
                                                <a href="news-details.html">Bring to the table win-win survival</a>
                                            </h3>
                                        </div>
                                    </li>
                                </ul>
                            </div> */}





<div className="donation-details__sidebar-shaare-cause">
                                <div className="sidebar-shape-1" style={{backgroundImage: 'url(assets/images/shapes/sidebar-shape-1.png)' }}></div>
                                <h3 className="donation-details__sidebar-shaare-cause-title">Share</h3>
                                <div className="donation-details__sidebar-shaare-cause-social">
                                    <a href="https://facebook.com/"><i className="fab fa-twitter"></i></a>
                                    <a href="https://facebook.com/"><i className="fab fa-facebook"></i></a>
                                    <a href="https://facebook.com/"><i className="fab fa-pinterest-p"></i></a>
                                    <a href="https://facebook.com/"><i className="fab fa-instagram"></i></a>
                                </div>
                            </div>


                            <br/>

<div className="causes-one__single">
                                <div className="causes-one__img">
                                    <img src="assets/images/resources/causes-1-3.jpg" alt="" />
                                    <div className="causes-one__cat">
                                        <p>Donated Items</p>
                                    </div>
                                </div>
                                <div className="causes-one__content">
                                    <h3 className="causes-one__title"><a href="donation-details.html">
                                    Nourishing Communities, Transforming Lives
                                    </a>
                                    </h3>
                                    <p className="causes-one__text">
                                    See the tangible impact of your support. Discover how your generosity has fueled our fight against hunger and malnutrition in rural areas.
                                    </p>
                                    {/* <div className="causes-one__progress">
                                        <div className="causes-one__progress-shape" style={{backgroundImage: 'url(assets/images/shapes/causes-one-progress-shape-1.png)'}}>
                                        </div>
                                        <div className="bar">
                                            <div className="bar-inner count-bar" data-percent="36%">
                                                <div className="count-text">36%</div>
                                            </div>
                                        </div>
                                        <div className="causes-one__goals">
                                            <p><span>$25,270</span> Raised</p>
                                            <p><span>$30,000</span> Goal</p>
                                        </div>
                                    </div> */}
                                </div>
                            </div>





                        </div>




                   

        </div>
  );
}
export default SidebarTab;
