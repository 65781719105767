import React from 'react';
// import { Link } from 'react-router-dom';

function VolunteerTab() {
  return (
    <div>
  {/*Feature One Start*/}
  <section className="feature-one">
            <div className="container">
                <div className="row">
                    <div className="col-xl-6 col-lg-6  wow slideInLeft" data-wow-delay="100ms" data-wow-duration="2500ms">
                        <div className="feature-one__single">
                            <div className="feature-one__single-bg" style={{backgroundImage: 'url(assets/images/shapes/feature-one-shape-1.png)' }}></div>
                            <div className="feature-one__top">
                                <div className="feature-one__top-inner">
                                    <div className="feature-one__top-icon">
                                        <span className="icon-help"></span>
                                    </div>
                                    <div className="feature-one__top-title-box">
                                        <h3 className="feature-one__top-title"><a href="become-volunteer.html">Become  a
                                                volunteer</a></h3>
                                    </div>
                                </div>
                            </div>
                            <p className="feature-one__text">
                            Make a Difference Through Volunteering.</p>
                            <ul className="list-unstyled feature-one__point">
                                <li>
                                  
                                    <div className="icon">
                                        <span className="fa fa-check"></span>
                                    </div>
                                    <div className="text">
                                        <p> Develop new skills, gain valuable experience, and expand your network.</p>
                                    </div>
                                </li>
                                <li>
                                    <div className="icon">
                                        <span className="fa fa-check"></span>
                                    </div>
                                    <div className="text">
                                        <p> Make a positive impact on your community and help those in need. </p>
                                    </div>
                                </li>
                                <li>
                                    <div className="icon">
                                        <span className="fa fa-check"></span>
                                    </div>
                                    <div className="text">
                                        <p> Connect with like-minded individuals and build lasting friendships. </p>
                                    </div>
                                </li>
                                
                                 
                                {/* <li>
                                    <div className="icon">
                                        <span className="fa fa-check"></span>
                                    </div>
                                    <div className="text">
                                        <p> Overcome challenges, build self-esteem, and feel a sense of accomplishment. </p>
                                    </div>
                                </li>
                                */}
                                <li>
                                    <div className="icon">
                                        <span className="fa fa-check"></span>
                                    </div>
                                    <div className="text">
                                        <p> Experience happiness overall life satisfaction. </p>
                                    </div>
                                </li> 
                            </ul>
                            <a href="contact" className="thm-btn feature-one__btn">View details</a>
                        </div>
                    </div>
                    <div className="col-xl-6 col-lg-6  wow slideInRight" data-wow-delay="100ms" data-wow-duration="2500ms">
                        <div className="feature-one__single feature-one__single--two">
                            <div className="feature-one__single-bg" style={{backgroundImage: 'url(assets/images/shapes/feature-one-shape-1.png)' }}></div>
                            <div className="feature-one__top">
                                <div className="feature-one__top-inner">
                                    <div className="feature-one__top-icon feature-one__top-icon--two">
                                        <span className="icon-gift-box"></span>
                                    </div>








                                    <div className="feature-one__top-title-box">
                                        <h3 className="feature-one__top-title"><a href="about.html">Send
                                                a gift for <br />
                                                childrens</a></h3>
                                    </div>
                                </div>
                            </div>
                            <p className="feature-one__text">
                            Your Support Changes Lives
                            </p>
                            <ul className="list-unstyled feature-one__point">
                                
                                <li>
                                    <div className="icon">
                                        <span className="fa fa-check"></span>
                                    </div>
                                    <div className="text">
                                        <p> Enjoy potential tax deductions for your charitable contributions. </p>
                                    </div>
                                </li>
                                <li>
                                    <div className="icon">
                                        <span className="fa fa-check"></span>
                                    </div>
                                    <div className="text">
                                        <p> Join a community of compassionate individuals making a difference.</p>
                                    </div>
                                </li>
                                <li>
                                    <div className="icon">
                                        <span className="fa fa-check"></span>
                                    </div>
                                    <div className="text">
                                        <p> Encourage friends and family to support the cause.</p>
                                    </div>
                                </li>
                                <li>
                                    <div className="icon">
                                        <span className="fa fa-check"></span>
                                    </div>
                                    <div className="text">
                                        <p>  Help build a better future for generations to come.</p>
                                    </div>
                                </li>
                                <li>
                                    <div className="icon">
                                        <span className="fa fa-check"></span>
                                    </div>
                                    <div className="text">
                                        <p> Know that your donation is making a real difference in people's lives.</p>
                                    </div>
                                </li>
                            </ul>
                            <a href="donations" className="thm-btn feature-one__btn">View details</a>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        {/*Feature One End*/}
        </div>
  );
}
export default VolunteerTab;
