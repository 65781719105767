import React from 'react';
// import { Link } from 'react-router-dom';

function EventTab() {
  return (
    <div>
{/*Events One Start*/}
<section className="events-one">
            <div className="events-one-shape-1" style={{backgroundImage: 'url(assets/images/shapes/events-one-shape-1.png) ' }}>
            </div>
            <div className="container">
                <div className="row">
                    <div className="col-xl-4 col-lg-4">
                        <div className="events-one__left">
                            <div className="section-title text-left">
                                <span className="section-title__tagline">Our events</span>
                                <h2 className="section-title__title">Join us in making a difference!</h2>
                            </div>
                            <p className="events-one__text-1">
                             Tynachyomaa Charitable Foundation is always hosting series of exciting events to raise awareness and funds for our cause.
                            </p>
                            <a href="events" className="thm-btn events-one__btn">Discover More</a>
                        </div>
                    </div>
                    <div className="col-xl-8 col-lg-8">
                        <div className="events-one__right">
                            <div className="events-one__carousel owl-carousel owl-theme thm-owl__carousel" data-owl-options='{
                                "loop": true,
                                "autoplay": true,
                                "margin": 20,
                                "nav": true,
                                "dots": false,
                                "smartSpeed": 500,
                                "autoplayTimeout": 10000,
                                "navText": ["<span className=\"icon-left-arrow\"></span>","<span className=\"icon-right-arrow\"></span>"],
                                "responsive": {
                                    "0": {
                                        "items": 1
                                    },
                                    "768": {
                                        "items": 2
                                    },
                                    "992": {
                                        "items": 2
                                    },
                                    "1200": {
                                        "items": 3
                                    }
                                }
                            }'>
                                <div className="item">
                                    {/*Events One Single Start*/}
                                    <div className="events-one__single">
                                        <div className="events-one__img">
                                            <img src="assets/images/events/event-1.jpg" alt="" />
                                            <div className="events-one__date">
                                                <p>TynaChyomaa</p>
                                            </div>
                                            <div className="events-one__content">
                                                <ul className="list-unstyled events-one__meta">
                                                    {/* <li><i className="fas fa-clock"></i>8:00pm</li> */}
                                                    <li><i className="fas fa-map-marker-alt"></i>Owerri-North, Imo State</li>
                                                </ul>
                                                <h3 className="events-one__title"><a href="event-details.html"> Gala for a Cause </a></h3>
                                            </div>
                                        </div>
                                    </div>
                                    {/*Events One Single End*/}
                                </div>
                                <div className="item">
                                    {/*Events One Single Start*/}
                                    <div className="events-one__single">
                                        <div className="events-one__img">
                                            <img src="assets/images/events/event-2.jpg" alt="" />
                                            <div className="events-one__date">
                                                <p>TynaChyomaa</p>
                                            </div>
                                            <div className="events-one__content">
                                                <ul className="list-unstyled events-one__meta">
                                                    {/* <li><i className="fas fa-clock"></i>8:00pm</li> */}
                                                    <li><i className="fas fa-map-marker-alt"></i>Ikeja - Lagos State.</li>
                                                </ul>
                                                <h3 className="events-one__title"><a href="event-details.html">Family Fun Day</a></h3>
                                            </div>
                                        </div>
                                    </div>
                                    {/*Events One Single End*/}
                                </div>
                                <div className="item">
                                    {/*Events One Single Start*/}
                                    <div className="events-one__single">
                                        <div className="events-one__img">
                                            <img src="assets/images/events/event-3.jpg" alt="" />
                                            <div className="events-one__date">
                                                <p>TynaChyomaa</p>
                                            </div>
                                            <div className="events-one__content">
                                                <ul className="list-unstyled events-one__meta">
                                                    {/* <li><i className="fas fa-clock"></i>8:00pm</li> */}
                                                    <li><i className="fas fa-map-marker-alt"></i>Republic of Ireland.</li>
                                                </ul>
                                                <h3 className="events-one__title"><a href="event-details.html">Volunteer Appreciation Brunch</a></h3>
                                            </div>
                                        </div>
                                    </div>
                                    {/*Events One Single End*/}
                                </div>
                                <div className="item">
                                    {/*Events One Single Start*/}
                                    <div className="events-one__single">
                                        <div className="events-one__img">
                                            <img src="assets/images/events/event-4.jpg" alt="" />
                                            <div className="events-one__date">
                                                <p>TynaChyomaa</p>
                                            </div>
                                            <div className="events-one__content">
                                                <ul className="list-unstyled events-one__meta">
                                                    {/* <li><i className="fas fa-clock"></i>8:00pm</li> */}
                                                    <li><i className="fas fa-map-marker-alt"></i>Owerri North LGA, imo state</li>
                                                </ul>
                                                <h3 className="events-one__title"><a href="event-details.html">
                                                Educational Workshop Series </a></h3>
                                            </div>
                                        </div>
                                    </div>
                                    {/*Events One Single End*/}
                                </div>
                                <div className="item">
                                    {/*Events One Single Start*/}
                                    <div className="events-one__single">
                                        <div className="events-one__img">
                                            <img src="assets/images/events/event-5.jpg" alt="" />
                                            <div className="events-one__date">
                                                <p>TynaChyomaa</p>
                                            </div>
                                            <div className="events-one__content">
                                                <ul className="list-unstyled events-one__meta">
                                                    {/* <li><i className="fas fa-clock"></i>8:00pm</li> */}
                                                    <li><i className="fas fa-map-marker-alt"></i> Owerri North LGA, imo state</li>
                                                </ul>
                                                <h3 className="events-one__title"><a href="event-details.html">Health and Wellness Fair</a></h3>
                                            </div>
                                        </div>
                                    </div>
                                    {/*Events One Single End*/}
                                </div>
                                <div className="item">
                                    {/*Events One Single Start*/}
                                    <div className="events-one__single">
                                        <div className="events-one__img">
                                            <img src="assets/images/events/event-6.jpg" alt="" />
                                            <div className="events-one__date">
                                                <p>TynaChyomaa</p>
                                            </div>
                                            <div className="events-one__content">
                                                <ul className="list-unstyled events-one__meta">
                                                    {/* <li><i className="fas fa-clock"></i>8:00pm</li> */}
                                                    <li><i className="fas fa-map-marker-alt"></i> Ikeja - Lagos State. </li>
                                                </ul>
                                                <h3 className="events-one__title"><a href="events"> Youth Empowerment Conference </a></h3>
                                            </div>
                                        </div>
                                    </div>
                                    {/*Events One Single End*/}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        {/*Events One End*/}

        </div>
  );
}
export default EventTab;
