import React from 'react';
import { Link } from 'react-router-dom';

function GalleryFull() {
  return (
    <div> 

{ /*Gallery Page Start*/ }
        <section className="gallery-carousel-page">
            <div className="container">
                <div className="gallery-carousel thm-owl__carousel owl-theme owl-carousel carousel-dot-style" data-owl-options='{
                    "items": 3,
                    "margin": 30,
                    "smartSpeed": 700,
                    "loop":true,
                    "autoplay": 6000,
                    "nav":false,
                    "dots":true,
                    "navText": ["<span className=\"fa fa-angle-left\"></span>","<span className=\"fa fa-angle-right\"></span>"],
                    "responsive":{
                        "0":{
                            "items":1
                        },
                        "768":{
                            "items":2
                        },
                        "992":{
                            "items": 3
                        }
                    }
                }'>
                    { /*Gallery Page Single Start*/ }
                    <div className="item">
                        <div className="gallery-page__single">
                            <div className="gallery-page__img">
                                <img src="assets/images/gallery/tyna-1.jpeg" alt="" />
                                <a href="assets/images/gallery/tyna-1.jpeg" className="img-popup"> </a>
                                { /* /.img-popup */ }
                                <div className="gallery-page__content">
                                    <p className="gallery-page__sub-title">TynaChyomaa</p>
                                    <h5 className="gallery-page__title"><a href="gallery">Food Support</a></h5>
                                </div>
                            </div>
                        </div>
                    </div>
                    { /*Gallery Page Single End*/ }
                    { /*Gallery Page Single Start*/ }
                    <div className="item">
                        <div className="gallery-page__single">
                            <div className="gallery-page__img">
                                <img src="assets/images/gallery/tyna-2.jpeg" alt="" />
                                <a href="assets/images/gallery/tyna-2.jpeg" className="img-popup"> </a>
                                { /* /.img-popup */ }
                                <div className="gallery-page__content">
                                    <p className="gallery-page__sub-title">TynaChyomaa</p>
                                    <h5 className="gallery-page__title"><a href="gallery">Food Support</a></h5>
                                </div>
                            </div>
                        </div>
                    </div>
                    { /*Gallery Page Single End*/ }
                    { /*Gallery Page Single Start*/ }
                    <div className="item">
                        <div className="gallery-page__single">
                            <div className="gallery-page__img">
                                <img src="assets/images/gallery/tyna-3.jpeg" alt="" />
                                <a href="assets/images/gallery/tyna-3.jpeg" className="img-popup"> </a>
                                { /* /.img-popup */ }
                                <div className="gallery-page__content">
                                    <p className="gallery-page__sub-title">TynaChyomaa</p>
                                    <h5 className="gallery-page__title"><a href="gallery">Food Support</a></h5>
                                </div>
                            </div>
                        </div>
                    </div>
                    { /*Gallery Page Single End*/ }
                    { /*Gallery Page Single Start*/ }
                    <div className="item">
                        <div className="gallery-page__single">
                            <div className="gallery-page__img">
                                <img src="assets/images/gallery/tyna-4.jpeg" alt="" />
                                <a href="assets/images/gallery/tyna-4.jpeg" className="img-popup"> </a>
                                { /* /.img-popup */ }
                                <div className="gallery-page__content">
                                    <p className="gallery-page__sub-title">TynaChyomaa</p>
                                    <h5 className="gallery-page__title"><a href="gallery">Food Support</a></h5>
                                </div>
                            </div>
                        </div>
                    </div>
                    { /*Gallery Page Single End*/ }
                    { /*Gallery Page Single Start*/ }
                    <div className="item">
                        <div className="gallery-page__single">
                            <div className="gallery-page__img">
                                <img src="assets/images/gallery/tyna-5.jpeg" alt="" />
                                <a href="assets/images/gallery/tyna-5.jpeg" className="img-popup"> </a>
                                { /* /.img-popup */ }
                                <div className="gallery-page__content">
                                    <p className="gallery-page__sub-title">TynaChyomaa</p>
                                    <h5 className="gallery-page__title"><a href="gallery">Food Support</a></h5>
                                </div>
                            </div>
                        </div>
                    </div>
                    { /*Gallery Page Single End*/ }
                    { /*Gallery Page Single Start*/ }
                    <div className="item">
                        <div className="gallery-page__single">
                            <div className="gallery-page__img">
                                <img src="assets/images/gallery/tyna-6.jpeg" alt="" />
                                <a href="assets/images/gallery/tyna-6.jpeg" className="img-popup"> </a>
                                { /* /.img-popup */ }
                                <div className="gallery-page__content">
                                    <p className="gallery-page__sub-title">TynaChyomaa</p>
                                    <h5 className="gallery-page__title"><a href="gallery">Food Support</a></h5>
                                </div>
                            </div>
                        </div>
                    </div>
                    { /*Gallery Page Single End*/ }
                    
                    { /*Gallery Page Single Start*/ }
                    <div className="item">
                        <div className="gallery-page__single">
                            <div className="gallery-page__img">
                                <img src="assets/images/gallery/tyna-7.jpeg" alt="" />
                                <a href="assets/images/gallery/tyna-7.jpeg" className="img-popup"> </a>
                                { /* /.img-popup */ }
                                <div className="gallery-page__content">
                                    <p className="gallery-page__sub-title">TynaChyomaa</p>
                                    <h5 className="gallery-page__title"><a href="gallery">Food Support</a></h5>
                                </div>
                            </div>
                        </div>
                    </div>
                    { /*Gallery Page Single End*/ }
                    { /*Gallery Page Single Start*/ }
                    <div className="item">
                        <div className="gallery-page__single">
                            <div className="gallery-page__img">
                                <img src="assets/images/gallery/tyna-8.jpeg" alt="" />
                                <a href="assets/images/gallery/tyna-8.jpeg" className="img-popup"> </a>
                                { /* /.img-popup */ }
                                <div className="gallery-page__content">
                                    <p className="gallery-page__sub-title">TynaChyomaa</p>
                                    <h5 className="gallery-page__title"><a href="gallery">Food Support</a></h5>
                                </div>
                            </div>
                        </div>
                    </div>
                    { /*Gallery Page Single End*/ }
                    
                    { /*Gallery Page Single Start*/ }
                    <div className="item">
                        <div className="gallery-page__single">
                            <div className="gallery-page__img">
                                <img src="assets/images/gallery/tyna-9.jpeg" alt="" />
                                <a href="assets/images/gallery/tyna-9.jpeg" className="img-popup"> </a>
                                { /* /.img-popup */ }
                                <div className="gallery-page__content">
                                    <p className="gallery-page__sub-title">TynaChyomaa</p>
                                    <h5 className="gallery-page__title"><a href="gallery">Food Support</a></h5>
                                </div>
                            </div>
                        </div>
                    </div>
                    { /*Gallery Page Single End*/ }

                    
                    { /*Gallery Page Single Start*/ }
                    <div className="item">
                        <div className="gallery-page__single">
                            <div className="gallery-page__img">
                                <img src="assets/images/gallery/tyna-10.jpeg" alt="" />
                                <a href="assets/images/gallery/tyna-10.jpeg" className="img-popup"> </a>
                                { /* /.img-popup */ }
                                <div className="gallery-page__content">
                                    <p className="gallery-page__sub-title">TynaChyomaa</p>
                                    <h5 className="gallery-page__title"><a href="gallery">Food Support</a></h5>
                                </div>
                            </div>
                        </div>
                    </div>
                    { /*Gallery Page Single End*/ }

                    
                    { /*Gallery Page Single Start*/ }
                    <div className="item">
                        <div className="gallery-page__single">
                            <div className="gallery-page__img">
                                <img src="assets/images/gallery/tyna-11.jpeg" alt="" />
                                <a href="assets/images/gallery/tyna-11.jpeg" className="img-popup"> </a>
                                { /* /.img-popup */ }
                                <div className="gallery-page__content">
                                    <p className="gallery-page__sub-title">TynaChyomaa</p>
                                    <h5 className="gallery-page__title"><a href="gallery">Food Support</a></h5>
                                </div>
                            </div>
                        </div>
                    </div>
                    { /*Gallery Page Single End*/ }

                    
                    { /*Gallery Page Single Start*/ }
                    <div className="item">
                        <div className="gallery-page__single">
                            <div className="gallery-page__img">
                                <img src="assets/images/gallery/tyna-12.jpeg" alt="" />
                                <a href="assets/images/gallery/tyna-12.jpeg" className="img-popup"> </a>
                                { /* /.img-popup */ }
                                <div className="gallery-page__content">
                                    <p className="gallery-page__sub-title">TynaChyomaa</p>
                                    <h5 className="gallery-page__title"><a href="gallery">Food Support</a></h5>
                                </div>
                            </div>
                        </div>
                    </div>
                    { /*Gallery Page Single End*/ }

                    
                    { /*Gallery Page Single Start*/ }
                    <div className="item">
                        <div className="gallery-page__single">
                            <div className="gallery-page__img">
                                <img src="assets/images/gallery/tyna-13.jpeg" alt="" />
                                <a href="assets/images/gallery/tyna-13.jpeg" className="img-popup"> </a>
                                { /* /.img-popup */ }
                                <div className="gallery-page__content">
                                    <p className="gallery-page__sub-title">TynaChyomaa</p>
                                    <h5 className="gallery-page__title"><a href="gallery">Food Support</a></h5>
                                </div>
                            </div>
                        </div>
                    </div>
                    { /*Gallery Page Single End*/ }

                    
                    { /*Gallery Page Single Start*/ }
                    <div className="item">
                        <div className="gallery-page__single">
                            <div className="gallery-page__img">
                                <img src="assets/images/gallery/tyna-13.jpeg" alt="" />
                                <a href="assets/images/gallery/tyna-13.jpeg" className="img-popup"> </a>
                                { /* /.img-popup */ }
                                <div className="gallery-page__content">
                                    <p className="gallery-page__sub-title">TynaChyomaa</p>
                                    <h5 className="gallery-page__title"><a href="gallery">Food Support</a></h5>
                                </div>
                            </div>
                        </div>
                    </div>
                    { /*Gallery Page Single End*/ }

                    
                    { /*Gallery Page Single Start*/ }
                    <div className="item">
                        <div className="gallery-page__single">
                            <div className="gallery-page__img">
                                <img src="assets/images/gallery/tyna-14.jpeg" alt="" />
                                <a href="assets/images/gallery/tyna-14.jpeg" className="img-popup"> </a>
                                { /* /.img-popup */ }
                                <div className="gallery-page__content">
                                    <p className="gallery-page__sub-title">TynaChyomaa</p>
                                    <h5 className="gallery-page__title"><a href="gallery">Food Support</a></h5>
                                </div>
                            </div>
                        </div>
                    </div>
                    { /*Gallery Page Single End*/ }

                    
                    { /*Gallery Page Single Start*/ }
                    <div className="item">
                        <div className="gallery-page__single">
                            <div className="gallery-page__img">
                                <img src="assets/images/gallery/tyna-15.jpeg" alt="" />
                                <a href="assets/images/gallery/tyna-15.jpeg" className="img-popup"> </a>
                                { /* /.img-popup */ }
                                <div className="gallery-page__content">
                                    <p className="gallery-page__sub-title">TynaChyomaa</p>
                                    <h5 className="gallery-page__title"><a href="gallery">Food Support</a></h5>
                                </div>
                            </div>
                        </div>
                    </div>
                    { /*Gallery Page Single End*/ }

                    
                    { /*Gallery Page Single Start*/ }
                    <div className="item">
                        <div className="gallery-page__single">
                            <div className="gallery-page__img">
                                <img src="assets/images/gallery/tyna-16.jpeg" alt="" />
                                <a href="assets/images/gallery/tyna-16.jpeg" className="img-popup"> </a>
                                { /* /.img-popup */ }
                                <div className="gallery-page__content">
                                    <p className="gallery-page__sub-title">TynaChyomaa</p>
                                    <h5 className="gallery-page__title"><a href="gallery">Food Support</a></h5>
                                </div>
                            </div>
                        </div>
                    </div>
                    { /*Gallery Page Single End*/ }

                    
                    { /*Gallery Page Single Start*/ }
                    <div className="item">
                        <div className="gallery-page__single">
                            <div className="gallery-page__img">
                                <img src="assets/images/gallery/tyna-17.jpeg" alt="" />
                                <a href="assets/images/gallery/tyna-17.jpeg" className="img-popup"> </a>
                                { /* /.img-popup */ }
                                <div className="gallery-page__content">
                                    <p className="gallery-page__sub-title">TynaChyomaa</p>
                                    <h5 className="gallery-page__title"><a href="gallery">Food Support</a></h5>
                                </div>
                            </div>
                        </div>
                    </div>
                    { /*Gallery Page Single End*/ }

                    
                    { /*Gallery Page Single Start*/ }
                    <div className="item">
                        <div className="gallery-page__single">
                            <div className="gallery-page__img">
                                <img src="assets/images/gallery/tyna-18.jpeg" alt="" />
                                <a href="assets/images/gallery/tyna-18.jpeg" className="img-popup"> </a>
                                { /* /.img-popup */ }
                                <div className="gallery-page__content">
                                    <p className="gallery-page__sub-title">TynaChyomaa</p>
                                    <h5 className="gallery-page__title"><a href="gallery">Food Support</a></h5>
                                </div>
                            </div>
                        </div>
                    </div>
                    { /*Gallery Page Single End*/ }

                    
                    { /*Gallery Page Single Start*/ }
                    <div className="item">
                        <div className="gallery-page__single">
                            <div className="gallery-page__img">
                                <img src="assets/images/gallery/tyna-19.jpeg" alt="" />
                                <Link to="assets/images/gallery/tyna-19.jpeg" className="img-popup" > </Link>  
                                { /* /.img-popup */ }
                                <div className="gallery-page__content">
                                    <p className="gallery-page__sub-title">TynaChyomaa</p>
                                    <h5 className="gallery-page__title"><a href="gallery">Food Support</a></h5>
                                </div>
                            </div>
                        </div>
                    </div>
                    { /*Gallery Page Single End*/ }

                    
                    { /*Gallery Page Single Start*/ }
                    <div className="item">
                        <div className="gallery-page__single">
                            <div className="gallery-page__img">
                                <img src="assets/images/gallery/tyna-20.jpeg" alt="" />
                                <a href="assets/images/gallery/tyna-20.jpeg" className="img-popup"> </a>
                                { /* /.img-popup */ }
                                <div className="gallery-page__content">
                                    <p className="gallery-page__sub-title">TynaChyomaa</p>
                                    <h5 className="gallery-page__title"><a href="gallery">Food Support</a></h5>
                                </div>
                            </div>
                        </div>
                    </div>
                    { /*Gallery Page Single End*/ }
                </div>
            </div>
        </section>
     
        </div>
  );
}
export default GalleryFull;
