import React from 'react';
// import { Link } from 'react-router-dom';

function BreadcrumAbout() {
  return (
    <div>
 {/* <!--Page Header Start--> */}
        <section className="page-header">
            <div className="page-header-bg" style={{backgroundImage: 'url(assets/images/backgrounds/page-header-bg-1.jpg)' }}>
            </div>
            <div className="container">
                <div className="page-header__inner">
                    <ul className="thm-breadcrumb list-unstyled">
                        <li><a href="/">Home</a></li>
                        <li><span>/</span></li>
                        <li className="active">About Us</li>
                    </ul>
                    <h2>About us</h2>
                </div>
            </div>
        </section>
        {/* <!--Page Header End--> */}

        </div>
  );
}
export default BreadcrumAbout;
