// import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import HeaderTab from '../comp/HeaderTab';
import BreadcrumEvent from '../comp/BreadcrumEvent';
import EventTab from '../comp/EventTab';
import BecomeVolunteerTab from '../comp/BecomeVolunteerTab';
import DonationTab from '../comp/DonationTab';
import FooterTab from '../comp/FooterTab';



function Events() {
  return (
    <div>
      <Helmet>
        <title>Events | TynaChyomaa Charitable Foundation </title>
        <meta name="keywords" content="events, TynaChyomaa, charitable foundation, upcoming events, past events, orphans, youth empowerment, vocational education, widows" />
        <meta name="description" content="Discover our upcoming events and learn about past initiatives. Join us for fundraising events, workshops, and community gatherings." />
      </Helmet>
     {/* <h1>About Us</h1>
      <Link to="/contact">Contact Us</Link> */}
         <HeaderTab />
         <BreadcrumEvent />
         <EventTab />
         <BecomeVolunteerTab />
         <DonationTab /> 
         <FooterTab />
    </div>
  );
}
export default Events;
